<template>
    <div class="table">
        <div class="container">
            <!-- <div class="handle-box">
                <el-input v-model="searchValus" placeholder="关键字" class="handle-input mr10"></el-input>
            </div> -->
            <div class="handle-box">
                <el-button type="primary" icon="search" @click="search">查询</el-button>
                <el-button type="primary" icon="delete" class="handle-del mr10" @click="addRow">新增</el-button>
            </div>
            <el-table class="z_default_table" :empty-text="emptyText" :row-style="rowStyle" v-loading="tableLoading"
                element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.5)" :data="tableData" border
                style="width: 100%" ref="multipleTable" use-virtual height="2000">
                <!-- <el-table-column type="selection" align="center" width="40"></el-table-column> -->
                <el-table-column align="center" prop="roleName" label="角色名称"></el-table-column>
                <el-table-column align="center" prop="remark" label="备注"></el-table-column>
                <el-table-column align="center" prop="createTime" label="创建时间"
                    :formatter="timeFormatter"></el-table-column>
                <el-table-column align="center" prop="updateTime" label="更新时间"
                    :formatter="timeFormatter"></el-table-column>
                <el-table-column align="center" label="操作" :width="260">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="editRow(scope.row)">编辑</el-button>
                        <el-button type="primary" size="small" @click="assignMenuRow(scope.row)">分配菜单</el-button>
                        <el-button type="danger" size="small" @click="delRow(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageIndex" :page-sizes="pageSizes" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="tableTotal"></el-pagination>
            </div>
        </div>

        <el-dialog title="新增角色" :visible.sync="addVisible" width="40%" class="dialog-border-radius">
            <el-form ref="addForm" :rules="rules" :model="addForm" label-width="80px" :label-position="labelPosition"
                class="dialog-form">
                <el-form-item prop="roleName" label="角色名称">
                    <el-input v-model="addForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="addForm.remark" :rows="4" :maxlength="200" resize="none"
                        show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm('addForm')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑角色" :visible.sync="editVisible" width="40%" class="dialog-border-radius">
            <el-form ref="form" :rules="rules" :model="form" label-width="80px" :label-position="labelPosition"
                class="dialog-form">
                <el-form-item prop="roleName" label="角色名称">
                    <el-input v-model="form.roleName"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="form.remark" :rows="4" :maxlength="200" resize="none"
                        show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm('form')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="分配菜单" :visible.sync="menuVisible" width="40%" class="dialog-border-radius">
            <!-- <el-checkbox-group v-model="roleMenuList">
                <el-checkbox class="menu-checkbox" v-for="item in menuList" :label="item.id" :key="item.id">{{
                    item.menuName ||
                    "暂无" }}</el-checkbox>
            </el-checkbox-group> -->
            <el-tree :data="menuList" ref="menuTree" :default-checked-keys="roleMenuList" show-checkbox default-expand-all
                node-key="id" :props="{ label: 'menu_name', children: 'children' }">
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="menuVisible = false">取 消</el-button>
                <el-button type="primary" @click="roleMenuConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import global from "@/common/global";
import { decodeUserInfo, currentDate, requestParams, handleTreeData } from "@/common/tools";
export default {
    components: {},
    data() {
        return {
            userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
            pageSize: global.pageSize,
            pageSizes: global.pageSizes,
            pageIndex: 1,
            tableData: [], //表格数据
            tableTotal: 0,
            searchValus: "",
            labelPosition: "right",
            indexArr: [],
            tableLoading: false,
            rules: {
                roleName: [
                    {
                        required: true,
                        message: "请输入角色名称",
                        trigger: "blur"
                    }
                ]
            },
            curRow: {},
            addVisible: false,
            addForm: {},
            editVisible: false,
            form: {},
            menuVisible: false,
            roleMenuList: [],
            menuList: []
        };
    },
    created() {
        this.getData();
    },
    updated() {

    },
    computed: {
    },
    methods: {
        rowStyle({ row, rowIndex }) {
            for (var i in this.indexArr) {
                if (rowIndex == this.indexArr[i].index) {
                    return "background:" + global.listColor + ";";
                }
            }
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getData();
        },
        getData() {
            this.tableLoading = true;
            var data = {
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                checkStatus: this.checkStatusType,
                searchValus: this.searchValus
            };
            this.emptyText = " ";
            this.$axios
                .post("/oa/role/list", this.$qs.stringify(data))
                .then(res => {
                    console.log(res);
                    this.tableData = res.data.data.rows;
                    this.tableLoading = false;
                    this.tableTotal = Number(res.data.data.total);
                    this.emptyText = "暂无数据";
                })
                .catch(error => {
                    this.emptyText = "暂无数据";
                    this.tableLoading = false;
                });
        },
        search() {
            this.pageIndex = 1;
            this.getData();
        },
        addRow() {
            this.addVisible = true;
        },
        addConfirm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let data = requestParams({ ...this.addForm }, false);
                    this.$axios
                        .post("/oa/role/save", this.$qs.stringify(data))
                        .then(({ data }) => {
                            this.addVisible = false;
                            this.getData();
                            this.$message[data.code == 0 ? "success" : "error"](data.message);
                        })
                        .catch(error => {
                            this.$message.error(error.message);
                        });
                }
            })
        },
        editRow(row) {
            this.form = { ...row };
            this.editVisible = true;
        },
        editConfirm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let { id, roleName, remark } = this.form;
                    let data = requestParams({ id, roleName, remark }, false);
                    this.$axios
                        .post("/oa/role/update", this.$qs.stringify(data))
                        .then(({ data }) => {
                            this.editVisible = false;
                            this.getData();
                            this.$message[data.code == 0 ? "success" : "error"](data.message);
                        })
                        .catch(error => {
                            this.$message.error(error.message);
                        });
                }
            })
        },
        delRow(row) {
            let { id, roleName } = row;
            this.$confirm(`确定删除当前角色(${roleName})吗？`, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                let data = requestParams({ id }, false);
                this.$axios
                    .post("/oa/role/delete", this.$qs.stringify(data))
                    .then(({ data }) => {
                        this.editVisible = false;
                        this.getData();
                        this.$message[data.code == 0 ? "success" : "error"](data.message);
                    })
                    .catch(error => {
                        this.$message.error(error.message);
                    });
            });
        },
        handleRoleTree(list) {
            let menuArr = [];
            global.totalMenuList.forEach((item, index) => {
                if (item.menu_url == "") {
                    menuArr.push({ ...item, id: `g_${index}` });
                }

                for (let j in list) {
                    if (list[j].router == item.menu_url) {
                        menuArr.push({ ...item, id: list[j].id })
                        break;
                    }
                }
            });

            let arr = handleTreeData(menuArr, {
                parentId: "menu_parentId",
                id: "menu_id"
            });
            this.roleMenuList = list.filter(item => item.isHaveMenu == '1').map(item => item.id);
            this.menuList = arr.filter(item => item.children && item.children.length > 0);
        },
        getRoleMenu() {
            let { id } = this.curRow;
            this.$axios
                .post("/oa/roleMenu/list", this.$qs.stringify({ roleId: id }))
                .then(({ data }) => {
                    let list = data.data || [];
                    this.handleRoleTree(list);
                    console.log(this.roleMenuList)
                })
                .catch(error => {
                    this.$message.error(error.message);
                });
        },
        assignMenuRow(row) {
            this.curRow = { ...row };
            this.menuVisible = true;
            this.getRoleMenu();
        },
        roleMenuConfirm() {
            let keys = this.$refs.menuTree.getCheckedKeys().filter(item => `${item}`.indexOf("g_") == -1);
            console.log(this.roleMenuList, keys);
            let { id } = this.curRow;
            this.$axios
                .post("/oa/roleMenu/save", this.$qs.stringify({
                    roleId: id,
                    menuIds: keys.join(",")
                }))
                .then(({ data }) => {
                    this.menuVisible = false;
                    this.$message[data.code == 0 ? "success" : "error"](data.message);
                })
                .catch(error => {
                    this.$message.error(error.message);
                });
        },
        timeFormatter(row, column, cellValue, index) {
            return currentDate({
                timestamp: cellValue
            });
        },
    }
};
</script>

<style scoped>
.table {
    height: 100%;
}

.table,
.container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

.table .el-table {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.search_select {
    width: 160px !important;
}

.handle-input {
    width: 160px;
    display: inline-block;
}

.del-dialog-cnt {
    font-size: 16px;
    text-align: center;
}

.select_name {
    display: inline-block;
    font-size: 14px;
}

.select_container {
    display: inline-block;
}

.select_container .el-date-editor.el-input {
    width: 140px;
}

.brandMs p {
    text-align: left;
}
</style>